












import { computed, defineComponent, PropType } from "@vue/composition-api";

import Links from "@/components/links/Links.vue";

export default defineComponent({
  name: "Indicators",
  props: {
    values: {
      type: Array as PropType<string[]>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: { Links },

  setup(props) {
    const hasValues = computed(() => {
      return props.values.length > 0;
    });

    return { hasValues };
  },
});
