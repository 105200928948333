














import { computed, defineComponent, PropType } from "@vue/composition-api";

import { Detail } from "@/types";

export default defineComponent({
  name: "Detail",
  props: {
    detail: {
      type: Object as PropType<Detail>,
      required: true,
    },
  },
  setup(props) {
    const hasReferenceLink = computed(() => {
      return props.detail.referenceLink !== null;
    });
    return { hasReferenceLink };
  },
});
