import { render, staticRenderFns } from "./h2.vue?vue&type=template&id=3b95ff42&scoped=true&"
import script from "./h2.vue?vue&type=script&lang=ts&"
export * from "./h2.vue?vue&type=script&lang=ts&"
import style0 from "./h2.vue?vue&type=style&index=0&id=3b95ff42&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b95ff42",
  null
  
)

export default component.exports