














import { computed, defineComponent, PropType } from "@vue/composition-api";

import { HeaderItem } from "@/types";

interface FlattenHeader {
  id: string;
  key: string;
  value: string | number;
}

export default defineComponent({
  name: "FlattenHeaders",
  props: {
    headers: {
      type: Array as PropType<HeaderItem[]>,
      required: true,
    },
  },
  setup(props) {
    const flattenHeaders = computed(() => {
      const headers: FlattenHeader[] = [];
      let index = 0;
      for (const header of props.headers) {
        const key = header.key;
        const values = header.values;
        for (const value of values) {
          index += 1;
          headers.push({
            id: key + index.toString(),
            key: key,
            value: value,
          });
        }
      }
      return headers;
    });

    const hasFlattenHeaders = computed(() => {
      return flattenHeaders.value.length > 0;
    });

    return { hasFlattenHeaders, flattenHeaders };
  },
});
