





























import { computed, defineComponent, PropType } from "@vue/composition-api";

import H3 from "@/components/ui/h3.vue";
import { Header, Received } from "@/types";
import { toCommaSeparatedString } from "@/utils/commaSeparated";
import { secondsToHumanize } from "@/utils/secondsToHumanize";

interface ReceivedWithIndex {
  index: number;
  received: Received;
}

export default defineComponent({
  name: "Hops",
  props: {
    header: {
      type: Object as PropType<Header>,
      required: true,
    },
  },
  components: { H3 },
  setup(props) {
    const receivedWithIndex = computed(() => {
      const received = props.header.received || [];

      const receivedWithIndex: ReceivedWithIndex[] = received.map(
        (recived_, index) => {
          return { index: index + 1, received: recived_ };
        }
      );
      return receivedWithIndex;
    });

    const hasReceivedWithIndex = computed(() => {
      return receivedWithIndex.value.length > 0;
    });

    return {
      hasReceivedWithIndex,
      receivedWithIndex,
      toCommaSeparatedString,
      secondsToHumanize,
    };
  },
});
