














import { defineComponent, PropType } from "@vue/composition-api";

import { Link } from "@/types";

export default defineComponent({
  name: "Link",
  props: {
    value: {
      type: String,
      required: true,
    },
    link: {
      type: Object as PropType<Link>,
      required: true,
    },
  },
});
