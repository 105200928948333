




































import { computed, defineComponent, PropType } from "@vue/composition-api";

import Indicators from "@/components/indicators/Indicators.vue";
import H3 from "@/components/ui/h3.vue";
import UTC from "@/components/ui/UTC.vue";
import { Header } from "@/types";
import { toCommaSeparatedString } from "@/utils/commaSeparated";

export default defineComponent({
  name: "BasicHeaders",
  props: {
    header: {
      type: Object as PropType<Header>,
      required: true,
    },
  },
  components: { Indicators, UTC, H3 },
  setup(props) {
    const emailType = "email";
    const emails = computed(() => {
      return [props.header.from];
    });

    return { emails, emailType, toCommaSeparatedString };
  },
});
