












































import { computed, defineComponent, PropType } from "@vue/composition-api";

import Content from "@/components/bodies/Content.vue";
import Indicators from "@/components/indicators/Indicators.vue";
import H3 from "@/components/ui/h3.vue";
import { Body } from "@/types";
export default defineComponent({
  name: "Body",
  props: {
    body: {
      type: Object as PropType<Body>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: { H3, Content, Indicators },
  setup(props) {
    const domainType = "domain";
    const emailType = "email";
    const ipAddressType = "ip_address";
    const sha256Type = "sha256";
    const urlType = "url";

    const header = computed(() => {
      return `#${props.index + 1}`;
    });

    return {
      header,
      domainType,
      emailType,
      ipAddressType,
      sha256Type,
      urlType,
    };
  },
});
