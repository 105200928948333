










































import { defineComponent, ref } from "@vue/composition-api";
import { useAsyncTask } from "vue-concurrency";
import VueMarkdown from "vue-markdown";

import { API } from "@/api";
import ResponseComponent from "@/components/Response.vue";
import { ErrorData, Response } from "@/types";
import { alertError } from "@/utils/alert";

export default defineComponent({
  name: "Home",
  components: {
    VueMarkdown,
    ResponseComponent,
  },

  setup(_, context) {
    const emlFile = ref<File | undefined>(undefined);

    const informationMessages = [
      "- EML(`.eml`) and MSG(`.msg`) formats are supported.",
      "  - The MSG file will be converted to the EML file before analyzing. The conversion might be lossy.",
      "- This app doesn't store any information you enter.",
    ].join("\n");

    const analyzeFileTask = useAsyncTask<Response, [File | undefined]>(
      async (_signal, file: File | undefined) => {
        return await API.analyzeFile(file);
      }
    );

    const parse = async () => {
      const loadingComponent = context.root.$buefy.loading.open({
        container: context.root.$el,
      });

      try {
        await analyzeFileTask.perform(emlFile.value);
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = (error as any).response.data as ErrorData;
        alertError(data, context);
      }
    };

    return { parse, analyzeFileTask, informationMessages, emlFile };
  },
});
