















import { computed, defineComponent, PropType } from "@vue/composition-api";

import Detail from "@/components/verdicts/Detail.vue";
import { Verdict } from "@/types";
export default defineComponent({
  name: "Verdict",
  props: {
    verdict: {
      type: Object as PropType<Verdict>,
      required: true,
    },
  },
  components: { Detail },
  setup(props) {
    const closable = false;
    const title = computed(() => {
      return `${props.verdict.name} (score: ${props.verdict.score || "N/A"})`;
    });
    const type = computed(() => {
      return props.verdict.malicious ? "is-warning" : "is-success";
    });
    const hasDetails = computed(() => {
      return props.verdict.details.length > 0;
    });

    return { closable, type, title, hasDetails };
  },
});
