

















import { computed, defineComponent, PropType } from "@vue/composition-api";

import AttachmentComponent from "@/components/attachments/Attachment.vue";
import H2 from "@/components/ui/h2.vue";
import { Attachment } from "@/types";

export default defineComponent({
  name: "Attachements",
  props: {
    attachments: {
      type: Array as PropType<Attachment[]>,
      required: true,
    },
  },
  components: { AttachmentComponent, H2 },
  setup(props) {
    const hasAttachments = computed((): boolean => {
      return props.attachments.length > 0;
    });
    return { hasAttachments };
  },
});
