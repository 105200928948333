
























import { computed, defineComponent, PropType } from "@vue/composition-api";

import SubmitterComponent from "@/components/submitters/Submitter.vue";
import { Submitters } from "@/submitters";
import { Attachment, SubmitType } from "@/types";

export default defineComponent({
  name: "Submitters",
  props: {
    value: {
      type: Object as PropType<Attachment>,
      required: true,
    },
    type: {
      type: Object as PropType<SubmitType>,
      required: true,
    },
  },
  components: { SubmitterComponent },
  setup(props) {
    const selectedSubmitters = computed(() => {
      if (props.type === undefined) {
        return Submitters;
      }
      return Submitters.filter((submitter) => submitter.type === props.type);
    });

    return { selectedSubmitters };
  },
});
