











import { defineComponent } from "@vue/composition-api";

import Navbar from "@/components/ui/Navbar.vue";

export default defineComponent({
  name: "App",
  components: {
    Navbar,
  },
});
